export const sportKeys = [
  'RUN',
  'SKI',
  'ILMAHIRVI',
  'ILMALUODIKKO',
  'METSASTYSHIRVI',
  'METSASTYSLUODIKKO',
  'METSASTYSHAULIKKO',
  'METSASTYSTRAP',
  'PIENOISHIRVI',
  'PIENOISLUODIKKO',
  'NORDIC',
  'EUROPEAN',
]

export const nationalRecordUrl =
  'https://metsastajaliitto.fi/metsastajalle/kilpailutoiminta/kilpailut-ja-tulokset/sm-ennatykset'
